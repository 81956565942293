<template>
    <div>
        <b-card :title="cardTitle" class="rounded-lg">
            <b-card-text> {{ $t('HomeCard.Subtitle') }} </b-card-text>
            <b-card-text>
                <b-row>
                    <Loading v-if="duplicatesLoading" />

                    <template v-else>
                        <template>
                            <b-col
                                v-for="(item, index) in duplicatesData"
                                :key="index"
                                xl="3"
                                sm="6"
                                style="padding: 0px"
                            >
                                <b-card class="shadow" style="height: 200px">
                                    <b-card-body
                                        class="shadow d-flex rounded-lg"
                                    >
                                        <img
                                            width="80"
                                            height="90"
                                            :src="
                                                require(`@/assets/duplioAsset/icons/${item.icon}`)
                                            "
                                        />
                                        <b-card-text
                                            class="pl-2 d-flex flex-column font-weight-bold mb-0"
                                            :class="{
                                                'justify-content-between':
                                                    index !== lastData,
                                            }"
                                        >
                                            <p
                                                class="mb-0 pb-0 h5 text-secondaryColor"
                                                v-text="$t(item.title)"
                                                style="
                                                    white-space: nowrap;
                                                    overflow: hidden;
                                                    text-overflow: ellipsis;
                                                    width: 100px;
                                                "
                                            />

                                            <h4
                                                class="font-weight-bolder mb-0"
                                                :class="{
                                                    'mt-2': index == lastData,
                                                }"
                                                v-text="item.amount"
                                            />

                                            <b-link
                                                v-if="index !== lastData"
                                                :to="{
                                                    name: 'duplicatesByResource',
                                                    params: {
                                                        resource: item.value,
                                                    },
                                                }"
                                                class="card-link"
                                            >
                                                {{ $t('HomeCard.SeeAll') }}
                                                >>
                                            </b-link>
                                        </b-card-text>
                                    </b-card-body>
                                </b-card>
                            </b-col>
                        </template>
                    </template>
                </b-row>
            </b-card-text>
        </b-card>

        <b-row>
            <b-col cols="12" md="6">
                <Notification />
            </b-col>
        </b-row>
    </div>
</template>

<script>
import Ripple from 'vue-ripple-directive';
import useAppConfig from '@core/app-config/useAppConfig';
import Notification from '@/@core/components/dashboard/Notification.vue';

export default {
    name: 'Dashboard',
    components: {
        Notification,
    },

    directives: {
        Ripple,
    },

    data() {
        return {
            config: useAppConfig(),
            duplicatesLoading: true,
            duplicatesData: [],
        };
    },
    computed: {
        lastData() {
            return this.duplicatesData.length - 1;
        },
        cardTitle() {
            return `${this.$t('Message.welcome')} ${this.$t('Message.back')}, ${
                this.userData.full_name
            }!`;
        },
    },

    methods: {
        getCurrentDuplicates() {
            this.$useJwt.getCurrentDuplicates().then(res => {
                const {data} = res.data;

                let obj = [
                    {
                        title: 'HomeCard.Invoices',
                        value: 'invoice',
                        icon: 'invoices.png',
                        amount: data.invoices,
                    },
                    {
                        title: 'HomeCard.Orders',
                        icon: 'order.png',
                        value: 'order',
                        amount: data.orders,
                    },
                    {
                        title: 'HomeCard.Voucher',
                        value: 'voucher',
                        icon: 'voucher.png',
                        amount: data.vouchers,
                    },
                    {
                        title: 'HomeCard.SupplierInvoices',
                        value: 'supplierinvoice',
                        icon: 'invoices.png',
                        amount: data.supplierinvoices,
                    },
                    {
                        title: 'HomeCard.Total',
                        icon: 'total.png',
                        amount: data.total,
                    },
                ];

                this.duplicatesData = obj;

                this.duplicatesLoading = false;
            });
        },

        init() {
            this.getCurrentDuplicates();
        },
    },

    mounted() {
        this.init();
    },
};
</script>
