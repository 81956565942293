var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{staticClass:"rounded-lg",attrs:{"title":_vm.cardTitle}},[_c('b-card-text',[_vm._v(" "+_vm._s(_vm.$t('HomeCard.Subtitle'))+" ")]),_c('b-card-text',[_c('b-row',[(_vm.duplicatesLoading)?_c('Loading'):[_vm._l((_vm.duplicatesData),function(item,index){return _c('b-col',{key:index,staticStyle:{"padding":"0px"},attrs:{"xl":"3","sm":"6"}},[_c('b-card',{staticClass:"shadow",staticStyle:{"height":"200px"}},[_c('b-card-body',{staticClass:"shadow d-flex rounded-lg"},[_c('img',{attrs:{"width":"80","height":"90","src":require(("@/assets/duplioAsset/icons/" + (item.icon)))}}),_c('b-card-text',{staticClass:"pl-2 d-flex flex-column font-weight-bold mb-0",class:{
                                            'justify-content-between':
                                                index !== _vm.lastData,
                                        }},[_c('p',{staticClass:"mb-0 pb-0 h5 text-secondaryColor",staticStyle:{"white-space":"nowrap","overflow":"hidden","text-overflow":"ellipsis","width":"100px"},domProps:{"textContent":_vm._s(_vm.$t(item.title))}}),_c('h4',{staticClass:"font-weight-bolder mb-0",class:{
                                                'mt-2': index == _vm.lastData,
                                            },domProps:{"textContent":_vm._s(item.amount)}}),(index !== _vm.lastData)?_c('b-link',{staticClass:"card-link",attrs:{"to":{
                                                name: 'duplicatesByResource',
                                                params: {
                                                    resource: item.value,
                                                },
                                            }}},[_vm._v(" "+_vm._s(_vm.$t('HomeCard.SeeAll'))+" >> ")]):_vm._e()],1)],1)],1)],1)})]],2)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('Notification')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }